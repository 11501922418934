import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { StoreButton } from '../components/store-button'
import specialFormatter from '../utility/special-formatter'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  render() {
    const post = get(this.props, 'data.contentfulBlogPost');
    const siteTitle = get(this.props, 'data.contentfulSite.htmlTitle');
    const allStore = get(this.props, 'data.allContentfulStoreItems').edges;
    const store = allStore[Math.floor(Math.random()*allStore.length)].node;
    let storeHtml = null;

    let game = null;
    if (post.games) {
      game = post.games[0];
    }

    if (this.state.mounted) {
        storeHtml = (
        <a href={store.url} className="store-promo">
          <span>Get Merchandise</span>
          <img src={store.imageUrl} />
          {store.name}
        </a>
      )
    }

    return (
      <Layout location={this.props.location}>
        <div className="blog-individual">
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div className="page-title">
            <div className="gradient">
              <div className="container">
                <Img fixed={post.author.image.fixed} className="img" />
                <p><a href="/blog/">Blog</a> &raquo;</p>
                <h1>{post.title}</h1>
                <p>
                  By{' '}
                  <span className="author">{post.author.name}</span>{' '}
                  {post.publishDate}
                </p>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container">
              <aside>
                <ul className="social-list">
                  <li><a href={`http://www.twitter.com/home/?status=http://www.supergiantgames.com${this.props.location.pathname}`} className="fab fa-twitter"> Twitter</a></li>
                  <li><a href={`http://www.facebook.com/share.php?u=http://www.supergiantgames.com${this.props.location.pathname}`} className="fab fa-facebook"> Facebook</a></li>
                  <li><a href={`/contact`} className="fas fa-envelope"> Contact</a></li>
                </ul>
              </aside>
              <article>
                <div
                  dangerouslySetInnerHTML={{
                    __html: (post.bodyLegacy ) ? post.bodyLegacy.bodyLegacy : specialFormatter(post.body.childContentfulRichText.html),
                  }}
                />
              </article>
              <section>
                {(game !== null) ?
                  <div>
                    <Link to={`/games/${game.slug}`}>
                      <Img fluid={game.boxArt.fluid} />
                    </Link>
                    <div style={{textAlign: "center", marginTop: '6px'}}>
                      {(game.steam !== null) ? <StoreButton link={game.steam} store="steam" />: null}
                      {(game.macAppStore !== null) ? <StoreButton link={game.macAppStore} store="macAppStore" />: null}
                      {(game.msStore !== null) ? <StoreButton link={game.msStore} store="msStore" />: null}
                      {(game.epicStore !== null) ? <StoreButton link={game.epicStore} store="epicStore" />: null}
                      {(game.nintendoStore !== null) ? <StoreButton link={game.nintendoStore} store="nintendoStore" />: null}
                      {(game.psStore !== null) ? <StoreButton link={game.psStore} store="psStore" />: null}
                    </div>
                    <hr />

                    {storeHtml}
                  </div>
                : null
                }

              </section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      bodyLegacy {
        bodyLegacy
      }
      author {
        name
        image {
          fixed(width: 100, height: 100, quality: 80) {
            ...GatsbyContentfulFixed
          }
        }
      }
      body {
        childContentfulRichText {
          html
        }
      }
      games {
        name
        publishDate(formatString: "MMMM Do, YYYY")
        slug
        color
        steam
        psStore
        macAppStore
        msStore
        epicStore
        nintendoStore
        faq {
          slug
        }
        epicStore
        boxArt {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed(width: 250, quality: 100) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    contentfulSite {
      twitter
      subtitle
      htmlTitle
    }
    allContentfulStoreItems(sort: { fields: [sortPriority], order: DESC }) {
      edges {
        node {
          name
          url
          imageUrl
        }
      }
    }
  }
`
